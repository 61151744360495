import React from "react";

export type SelectProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label?: string;
};

const Select = (
  { label, children, ...rest }: SelectProps,
  ref: React.Ref<HTMLSelectElement>
) => {
  return (
    <label className="select-label">
      <span>{label}</span>
      <select style={{ minWidth: "200px" }} {...rest} ref={ref}>
        {children}
      </select>
    </label>
  );
};

export default React.forwardRef(Select);

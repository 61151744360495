import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import "./App.css";
import {
  AnalysisResult,
  HesIntegration,
  HesIntegrations,
  RfCardType,
  RfCardTypes,
  schema,
  ServerType,
  ServerTypes,
} from "./interfaces/analysis-result";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content } from "pdfmake/interfaces";
import React from "react";
import FormField from "./components/FormField";
import CheckBox from "./components/CheckBox";
import OptionalFormField from "./components/OptionalFormField";
import Select from "./components/Select";
import NumberInput from "./components/NumberInput";
import { InfoProps } from "./components/Info";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const info: { [key: string]: InfoProps } = {
  qrCodeTriggerRemoteAccessInfo: {
    text: `Akıllı telefon uygulamasından karekod okutma butonuna basılır.
    Erişim noktasındaki karekod okutulur. Akıllı telefon uygulaması
    sunucu ile iletişime geçer ve senaryoya göre erişim noktasındaki
    cihaza aç komutu iletilir ya da personel devam kontrol amacıyla
    erişim kaydı alınır. Arzu edilirse erişim noktası için konum
    doğrulaması ayarı yapılabilir. Bu durumda akıllı telefonun
    belirtilen konumda olması gereklidir.`,
    img: "qrCodeTriggerRemoteAccessInfo.jpg",
  },
  qrCodeTriggerBluetoothAccess: {
    text: `Akıllı telefon uygulamasından karekod okutma butonuna basılır.
  Erişim noktasındaki karekod okutulur. Akıllı telefon uygulaması
  erişim noktasındaki cihaz ile bluetooth üzerinden iletişime geçer ve senaryoya göre erişim noktasındaki
  cihaza aç komutu iletilir ya da personel devam kontrol amacıyla
  erişim kaydı alınır. Uzaktan komuta göre avantajı fiziki olarak kullanıcının birkaç metre mesefade olduğundan emin olunmasıdır.`,
    img: "qrCodeTriggerBluetoothAccess.jpg",
  },

  smartPhoneNfc: {
    text: `NFC desteği olan Android cihazlarda, telefonu destekleyen cihaza 3-5cm mesafeden göstererek geçiş yapılabilir.`,
    img: "smartPhoneNfc.jpg",
  },
  smartPhoneBluetoohOnBackground: {
    text: `Bluetooth desteği olan cihazlarda, arka planda çalışma modu aktive edilirse, kullanıcı telefonu ile cihaza yaklaştığında geçiş yapabilir.
  Bu senaryo birbirine yakın turnike olan konumlar için uygun değildir!`,
    img: "smartPhoneBluetoohOnBackground.jpg",
  },
  cardAccess: {
    text: `Kurum tarafından dağıtılacak olan kartlar sisteme tanımlanır. Kullanıcılar kartlarını okuyucu cihaza göstererek geçiş yapabilirler.`,
    img: "cardAccess.jpg",
  },
  fingerPrint: {
    text: `Parmak izi doğrulama`,
    img: "fingerPrint.jpg",
  },
  fingerPrintAndMifareCard: {
    text: `Kullanıcının parmak izi verisi Mifare karta cihaz (armon V10 modeli) yardımı ile yüklenir. Kart kullanıcıya teslim edildiğinden parmak izi verisini
  kimseyle paylaşmamış olur. Geçiş yapılmak istendiğinde öncelikle kart cihaza okutulur, sonrasında parmak izi okutma yapılır. Cihaz kart içerisindeki veri ile okutulan parmak izi
  verisini eşleştirir. KVKK için tercih edilen bir yöntemdir.`,
    img: "fingerPrintAndMifareCard.jpg",
  },
  mobileCheckInForPacs: {
    text: `Bu yöntemle çalışanlar akıllı telefon uygulamasından çalışmaya başlama ve bitirme işlemi yapabilirler.
  Çalışma planına tanımlanacak bölgeler ile çalışanların check-in yapabilecekleri alanlar kısıtlanabilmektedir.`,
    img: "mobileCheckInForPacs.jpg",
  },
  readerReadQrCode: {
    text: `Akıllı telefon uygulamasında üretilen karekodu cihaza okutarak erişim yapılabilmektedir. Anlık HES doğrulaması için bu yöntemin edilinilmesi şarttır.
  Bu yöntem ziyaretçilerin geldiklerinde check-in yapmaları için de kullanılabilmektedir.`,
    img: "readerReadQrCode.jpg",
  },
  hesIntegration: {
    text: `Armon iki şekilde HES entegrasyonu yapabilmektedir. Erişim talep anında okutulan karekodu T.C. Sağlık Bakanlığının servislerinden doğrulayarak ve
  sisteme önden yüklenen HES kodlarını belirli aralıklarla kontrol ederek yöntemlerinden biri tercih edilebilir. Müşteri, Bakanlıktan erişim için kullanıcı adı ve şifre almalıdır. 
  Sistem alınan bu bilgiler ile servislere erişmektedir.`,
    img: "hesIntegration.jpg",
  },
  licencePlate: {
    text: `Uyumlu plaka tanıma kameraları ile araç geçişi yapılabilmektedir.`,
    img: "licencePlate.jpg",
  },
  vehicleUhfTag: {
    text: `UHF Anten altyapısı kurularak, araçlara tanımlanan etiketler üzerinden araç geçişi yapılabilmektedir.`,
    img: "vehicleUhfTag.jpg",
  },
};

const pdfSvg = (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: "24px",
      height: "24px",
      fill: "white",
      marginRight: "10px",
    }}
    x="0px"
    y="0px"
    viewBox="0 0 482.14 482.14"
  >
    <g>
      <path
        d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69
c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z"
      />
      <path
        d="M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394
c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z"
      />
      <path
        d="M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404
c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223
c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796
c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646
c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811
c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748
c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233
c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z
M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282
c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222
C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42
v19.875H314.892z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default function App() {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    control,
  } = useForm<AnalysisResult>({
    resolver: joiResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit: SubmitHandler<AnalysisResult> = (data) => {
    console.log(JSON.stringify(data, null, 4));
    const filename =
      new Intl.DateTimeFormat("tr-TR").format(new Date()) +
      "_" +
      data.customer +
      ".pdf";
    const header = (title: string) => {
      return [
        {
          text: " ",
          lineHeight: 1.5,
        },
        {
          text: title,
          lineHeight: 1.5,
          style: {
            bold: true,
            fontSize: 16,
          },
        },
      ];
    };
    const dataRow = (key: string, data: string) => {
      return {
        columns: [
          {
            text: key,
            width: 150,
            lineHeight: 1.15,
            style: {
              bold: true,
            },
          },
          {
            text: data,
          },
        ],
      };
    };

    const content: Content = [
      ...header("Müşteri Bilgileri"),
      dataRow("Adı", data.customer),
    ];
    if (data.contact) {
      content.push(dataRow("İletişim", data.contact));
    }
    if (data.requestedAccessMethods) {
      content.push(...header("Talep Edilen Erişim Yöntemleri"));
      const accessMethods: string[] = [];
      if (data.requestedAccessMethods.cardAccess) {
        accessMethods.push(data.requestedAccessMethods.cardAccess);
      }
      if (data.requestedAccessMethods.fingerPrint) {
        accessMethods.push("Parmaz İzi");
      }
      if (data.requestedAccessMethods.fingerPrintAndMifareCard) {
        accessMethods.push("Mifare Kart Üzerinde Parmak İzi");
      }
      if (data.requestedAccessMethods.hesIntegration) {
        accessMethods.push(
          data.requestedAccessMethods.hesIntegration === "onAccess"
            ? "Erişim Anında HES Karekodu Kontrolü"
            : "Periyodik Olarak HES Servisinden Kontrol"
        );
      }
      if (data.requestedAccessMethods.licencePlate) {
        accessMethods.push("Plaka Tanıma");
      }
      if (data.requestedAccessMethods.mobileCheckInForPacs) {
        accessMethods.push("Mobil Chech-In");
      }
      if (data.requestedAccessMethods.qrCodeTriggerBluetoothAccess) {
        accessMethods.push("Karekod ile Bluetooth Tetikleme");
      }
      if (data.requestedAccessMethods.qrCodeTriggerRemoteAccess) {
        accessMethods.push("Karekod ile Uzaktan Erişim Tetikleme");
      }
      if (data.requestedAccessMethods.readerReadQrCode) {
        accessMethods.push("Okuyucuya Karekod Okutarak");
      }
      if (data.requestedAccessMethods.smartPhoneBluetoohOnBackground) {
        accessMethods.push(
          "Akıllı Telefonun Bluetooth'u Arka Planda Çalışmaktayken"
        );
      }
      if (data.requestedAccessMethods.smartPhoneNfc) {
        accessMethods.push("Android Telefonların NFC Özelliği Çalışmaktayken");
      }
      if (data.requestedAccessMethods.vehicleUhfTag) {
        accessMethods.push("Araç UHF Etiketi");
      }
      content.push(
        dataRow(
          "Seçilen Yöntemler",
          accessMethods.length > 0
            ? accessMethods.join(",")
            : "Var olan yöntemlerden seçim yapılmadı!"
        )
      );

      if (data.requestedAccessMethods.others) {
        content.push(
          dataRow("Diğer Yöntemler", data.requestedAccessMethods.others)
        );
      }
    }

    content.push(...header("Talep Edilen Yazılım Modülleri"));
    content.push(
      dataRow(
        "Sunucu",
        data.requestedModules.serverType === "cloud" ? "Bulutta" : "Tesiste"
      )
    );

    content.push(
      dataRow(
        "Temel Modül Kimlik Sayısı",
        data.requestedModules.baseModuleCredentials.toString()
      )
    );
    if (data.requestedModules.pacsModuleCredentials) {
      content.push(
        dataRow(
          "PDKS Kimlik Sayısı",
          data.requestedModules.pacsModuleCredentials.toString()
        )
      );
    }
    if (data.requestedModules.socialModuleCredentials) {
      content.push(
        dataRow(
          "Sosyal Alan Modülü Kimlik Sayısı",
          data.requestedModules.socialModuleCredentials.toString()
        )
      );
    }

    if (data.requestedModules.visitorModule) {
      let text =
        "Kabul Nokta Sayısı: " +
        data.requestedModules.visitorModule.registrationPoints;
      if (data.requestedModules.visitorModule.visitorTablets > 0) {
        text +=
          "                  " +
          "Tablet Sayısı: " +
          data.requestedModules.visitorModule.visitorTablets;
      }
      content.push(dataRow("Ziyaretçi Modülü", text));
    }
    if (data.requestedModules.customReports) {
      content.push(
        dataRow(
          "Kuruma Özel Rapor Sayısı",
          data.requestedModules.customReports.toString()
        )
      );
    }
    if (data.physicalAccessPoints) {
      content.push(...header("Fiziki Altyapı"));
      if (data.physicalAccessPoints.doors > 0) {
        content.push(
          dataRow("Kapı Sayısı", data.physicalAccessPoints.doors.toString())
        );
      }
      if (data.physicalAccessPoints.turnstiles > 0) {
        content.push(
          dataRow(
            "Turnike Sayısı",
            data.physicalAccessPoints.turnstiles.toString()
          )
        );
      }
      if (data.physicalAccessPoints.barriers > 0) {
        content.push(
          dataRow(
            "Bariyer Sayısı",
            data.physicalAccessPoints.barriers.toString()
          )
        );
      }
    }

    if (data.notes) {
      content.push(dataRow("Notlar", data.notes));
    }

    pdfMake
      .createPdf({
        content,
      })
      .download(filename);
  };

  const readerReadQrCode = watch("requestedAccessMethods.readerReadQrCode");
  return (
    <>
      <header>
        <div>
          <img src="./vodafone.png" alt="Vodafone" />
          <span>armon™ Müşteri Analiz Formu</span>
        </div>
      </header>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
        <h2>Müşteri Bilgileri</h2>
        <FormField label="Müşteri" errors={errors.customer?.message}>
          <input name="customer" ref={register} />
        </FormField>
        <FormField label="İletişim Bilgileri" errors={errors.contact?.message}>
          <textarea name="contact" rows={5} ref={register} />
        </FormField>
        <h2>Talep Edilen Erişim Yöntemleri</h2>
        <FormField info={info.qrCodeTriggerRemoteAccessInfo}>
          <CheckBox
            name="requestedAccessMethods.qrCodeTriggerRemoteAccess"
            ref={register}
            label="Karekod network üzerinden açmayı tetikler"
          />
        </FormField>
        <FormField info={info.qrCodeTriggerBluetoothAccess}>
          <CheckBox
            name="requestedAccessMethods.qrCodeTriggerBluetoothAccess"
            ref={register}
            label={"Karekod Bluetooth üzerinden açmayı tetikler"}
          />
        </FormField>
        <FormField info={info.smartPhoneNfc}>
          <CheckBox
            name="requestedAccessMethods.smartPhoneNfc"
            ref={register}
            label="Android telefonların NFC özelliği arka planda otomatik tetikler"
          />
        </FormField>
        <FormField info={info.smartPhoneBluetoohOnBackground}>
          <CheckBox
            name="requestedAccessMethods.smartPhoneBluetoohOnBackground"
            ref={register}
            label="Akıllı telefonların Bluetooth özelliği arka planda otomatik tetikler"
          />
        </FormField>
        <OptionalFormField label="Kartlı Geçiş" info={info.cardAccess}>
          <Select
            defaultValue={"standartMifare" as RfCardType}
            ref={register}
            name="requestedAccessMethods.cardAccess"
          >
            {RfCardTypes.map((r) => (
              <option key={r.id} value={r.id}>
                {r.name}
              </option>
            ))}
          </Select>
        </OptionalFormField>
        <FormField info={info.fingerPrint}>
          <CheckBox
            name="requestedAccessMethods.fingerPrint"
            ref={register}
            label="Parmak izi"
          />
        </FormField>
        <FormField info={info.fingerPrintAndMifareCard}>
          <CheckBox
            name="requestedAccessMethods.fingerPrintAndMifareCard"
            ref={register}
            label="Mifare karta yüklü Parmak izi eşleştirmesi"
          />
        </FormField>
        <FormField info={info.mobileCheckInForPacs}>
          <CheckBox
            name="requestedAccessMethods.mobileCheckInForPacs"
            ref={register}
            label="PDKS amaçlı mobil check-in"
          />
        </FormField>
        <FormField info={info.readerReadQrCode}>
          <CheckBox
            name="requestedAccessMethods.readerReadQrCode"
            ref={register}
            label="Cihaza akıllı telefondaki karekodu okutarak"
          />
        </FormField>
        {readerReadQrCode ? (
          <OptionalFormField
            label="HES Entegrasyonu"
            info={info.hesIntegration}
          >
            <Select
              style={{ minWidth: "240px" }}
              defaultValue={"periodically" as HesIntegration}
              ref={register}
              name="requestedAccessMethods.hesIntegration"
            >
              {HesIntegrations.map((h) => (
                <option key={h.id} value={h.id}>
                  {h.name}
                </option>
              ))}
            </Select>
          </OptionalFormField>
        ) : (
          <FormField info={info.hesIntegration}>
            <Controller
              name="requestedAccessMethods.hesIntegration"
              control={control}
              render={({ value, onChange }) => {
                return (
                  <CheckBox
                    label="Periyodik HES Kodu Sorgulamaları"
                    checked={value === "periodically"}
                    onChange={(e) =>
                      onChange(e.target.checked ? "periodically" : undefined)
                    }
                  />
                );
              }}
            />
          </FormField>
        )}
        <FormField info={info.licencePlate}>
          <CheckBox
            name="requestedAccessMethods.licencePlate"
            ref={register}
            label="Plaka Tanıma"
          />
        </FormField>
        <FormField info={info.vehicleUhfTag}>
          <CheckBox
            name="requestedAccessMethods.vehicleUhfTag"
            ref={register}
            label="Araç HGS Etiket ile"
          />
        </FormField>
        <FormField
          label="Diğer"
          errors={errors.requestedAccessMethods?.others?.message}
        >
          <textarea
            name="requestedAccessMethods.others"
            ref={register}
            rows={5}
          />
        </FormField>
        <h2>Talep Edilen Modüller</h2>
        <FormField>
          <Select
            name="requestedModules.serverType"
            ref={register}
            label="Sunucu Tercihi"
            defaultValue={"cloud" as ServerType}
          >
            {ServerTypes.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <NumberInput
            type="number"
            min={25}
            name="requestedModules.baseModuleCredentials"
            ref={register}
            label="Temel Modül"
            defaultValue={25}
          />
        </FormField>
        <OptionalFormField label="Personel Devam Kontrol Sistemi ve İzin Yönetimi">
          <NumberInput
            min={25}
            name="requestedModules.pacsModuleCredentials"
            ref={register}
            defaultValue={25}
          />
        </OptionalFormField>
        <OptionalFormField label="Sosyal Alan Yönetimi">
          <NumberInput
            min={25}
            name="requestedModules.socialModuleCredentials"
            ref={register}
            defaultValue={25}
          />
        </OptionalFormField>
        <OptionalFormField block={true} label="Ziyaretçi Modülü">
          <div>
            <div>
              <NumberInput
                label="Ziyaretçi Kabul Noktası Sayısı"
                type="number"
                min={1}
                name="requestedModules.visitorModule.registrationPoints"
                ref={register}
                defaultValue={1}
              />
            </div>
            <div>
              <NumberInput
                label="Ziyaretçi Tableti Sayısı"
                type="number"
                min={0}
                name="requestedModules.visitorModule.visitorTablets"
                ref={register}
                defaultValue={0}
              />
            </div>
          </div>
        </OptionalFormField>
        <OptionalFormField label="Özelleştirilmiş Rapor">
          <NumberInput
            name="requestedModules.customReports"
            ref={register}
            min={0}
            defaultValue={0}
          />
        </OptionalFormField>
        <h2>Fiziki Altyapı</h2>
        <FormField>
          <NumberInput
            name="physicalAccessPoints.doors"
            ref={register}
            label="Kapı"
            min={0}
            defaultValue={0}
          />
        </FormField>
        <FormField>
          <NumberInput
            name="physicalAccessPoints.turnstiles"
            ref={register}
            label="Turnike"
            min={0}
            defaultValue={0}
          />
        </FormField>
        <FormField>
          <NumberInput
            name="physicalAccessPoints.barriers"
            ref={register}
            label="Bariyer"
            min={0}
            defaultValue={0}
          />
        </FormField>
        <FormField label="Notlar" errors={errors.notes?.message}>
          <textarea name="notes" ref={register} rows={5} />
        </FormField>
        <button type="submit">{pdfSvg} Kaydet</button>
      </form>
      <footer>ARMON © Her Hakkı Saklıdır 2021</footer>
    </>
  );
}

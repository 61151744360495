import React from "react";

export type NumberInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
};

const NumberInput = (
  { label, ...rest }: NumberInputProps,
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <label className="number-input-label">
      <span>{label}</span>
      <input type="number" {...rest} ref={ref} />
    </label>
  );
};

export default React.forwardRef(NumberInput);

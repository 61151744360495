import { FunctionComponent, useState } from "react";

export type InfoProps = {
  text?: string;
  img?: string;
};

const Info: FunctionComponent<InfoProps> = ({ text, img }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <>
      {(text || img) && (
        <div className="btn-info" onClick={() => setShowInfo(!showInfo)}>
          ?
        </div>
      )}
      {showInfo && (
        <div className="modal">
          <div className="modal-content">
            <div className="close" onClick={() => setShowInfo(false)}>
              &times;
            </div>
            {text && <i>{text}</i>}
            {img && <img className="modal-img" src={img} alt="" />}
          </div>
        </div>
      )}
    </>
  );
};

export default Info;

import Joi from "joi";

export type IdNamePair<T> = {
  id: T;
  name: string;
};

export type ServerType = "cloud" | "onPremise";
export const ServerTypes: IdNamePair<ServerType>[] = [
  {
    id: "cloud",
    name: "Bulutta",
  },
  {
    id: "onPremise",
    name: "Tesiste",
  },
];

export type RfCardType = "standartProx" | "standartMifare" | "hid";
export const RfCardTypes: IdNamePair<RfCardType>[] = [
  {
    id: "standartMifare",
    name: "Standart Mifare",
  },
  {
    id: "standartProx",
    name: "Standart Proximity",
  },
  {
    id: "hid",
    name: "HID",
  },
];

export type HesIntegration = "onAccess" | "periodically";
export const HesIntegrations: IdNamePair<HesIntegration>[] = [
  {
    id: "periodically",
    name: "Belirli Periyotlarda Sorguluyarak",
  },
  {
    id: "onAccess",
    name: "Erişim Anında",
  },
];

export type AnalysisResult = {
  customer: string;
  contact?: string;
  requestedModules: {
    serverType: ServerType;
    baseModuleCredentials: number;
    pacsModuleCredentials?: number;
    socialModuleCredentials?: number;
    visitorModule?: {
      registrationPoints: number;
      visitorTablets: number;
    };
    customReports?: number;
  };
  requestedAccessMethods: {
    qrCodeTriggerRemoteAccess?: boolean;
    qrCodeTriggerBluetoothAccess?: boolean;
    smartPhoneNfc?: boolean;
    smartPhoneBluetoohOnBackground?: boolean;
    cardAccess?: RfCardType;
    fingerPrint?: boolean;
    fingerPrintAndMifareCard?: boolean;
    mobileCheckInForPacs?: boolean;
    readerReadQrCode?: boolean;
    hesIntegration?: HesIntegration;
    licencePlate?: boolean;
    vehicleUhfTag?: boolean;
    others?: string;
  };
  physicalAccessPoints: {
    doors: number;
    turnstiles: number;
    barriers: number;
  };
  notes?: string;
};

export const schema = Joi.object<AnalysisResult>({
  customer: Joi.string().required().trim(true).max(255).messages({
    "string.empty": `Müşteri bilgileri boş bırakılamaz.`,
    "string.max": `En fazla 255 karakter girebilirsiniz.`,
  }),
  contact: Joi.string().max(511).trim(true).allow("").messages({
    "string.max": `En fazla 511 karakter girebilirsiniz.`,
  }),
  requestedAccessMethods: Joi.object({
    qrCodeTriggerRemoteAccess: Joi.boolean().optional(),
    qrCodeTriggerBluetoothAccess: Joi.boolean().optional(),
    smartPhoneNfc: Joi.boolean().optional(),
    smartPhoneBluetoohOnBackground: Joi.boolean().optional(),
    cardAccess: Joi.string()
      .valid(...RfCardTypes.map((r) => r.id))
      .optional(),
    fingerPrint: Joi.boolean().optional(),
    fingerPrintAndMifareCard: Joi.boolean().optional(),
    mobileCheckInForPacs: Joi.boolean().optional(),
    readerReadQrCode: Joi.boolean().optional(),
    hesIntegration: Joi.alternatives().conditional("readerReadQrCode", {
      is: true,
      then: Joi.string()
        .valid(...HesIntegrations.map((h) => h.id))
        .optional(),
      otherwise: Joi.string()
        .valid("periodically" as HesIntegration)
        .optional(),
    }),
    licencePlate: Joi.boolean().optional(),
    vehicleUhfTag: Joi.boolean().optional(),
    others: Joi.string().max(511).trim(true).allow("").messages({
      "string.max": `En fazla 511 karakter girebilirsiniz.`,
    }),
  }),
  requestedModules: Joi.object({
    serverType: Joi.string()
      .valid(...ServerTypes.map((s) => s.id))
      .required(),
    baseModuleCredentials: Joi.number().required().min(25),
    pacsModuleCredentials: Joi.number().optional().min(25),
    socialModuleCredentials: Joi.number().optional().min(25),
    visitorModule: Joi.object({
      registrationPoints: Joi.number().min(1).required(),
      visitorTablets: Joi.number().optional(),
    }).optional(),
    customReports: Joi.number().optional().min(0),
  }).required(),
  physicalAccessPoints: Joi.object({
    doors: Joi.number().optional().default(0),
    turnstiles: Joi.number().optional().default(0),
    barriers: Joi.number().optional().default(0),
  }).optional(),
  notes: Joi.string().max(1023).trim(true).allow("").messages({
    "string.max": `En fazla 1023 karakter girebilirsiniz.`,
  }),
});

import Info from "./Info";

export type InfoProps = {
  img?: string;
  text?: string;
};

export type FormFieldProps = {
  label?: string;
  children: JSX.Element;
  errors?: string;
  info?: InfoProps;
};

const FormField = ({ label, children, errors, info }: FormFieldProps) => {
  return (
    <div>
      <div className="form-field-controls">
        <span>
          <label className="form-field-label">
            {label && <div>{label}</div>}
            <div>{children}</div>
          </label>
        </span>
        {info && <Info text={info.text} img={info.img} />}
      </div>
      {errors && <p>{errors}</p>}
    </div>
  );
};

export default FormField;

import { useState } from "react";
import CheckBox from "./CheckBox";
import FormField, { FormFieldProps } from "./FormField";
import Info from "./Info";

export type OptionalFormFieldProps = FormFieldProps & {
  defaultEnabled?: boolean;
  block?: boolean;
};

const OptionalFormField = ({
  label,
  defaultEnabled = false,
  info,
  block,
  ...rest
}: OptionalFormFieldProps) => {
  const [enabled, setEnabled] = useState(defaultEnabled);
  return (
    <div
      className="optional-form-field"
      style={{ display: block ? "block" : "flex" }}
    >
      <CheckBox
        label={label}
        checked={enabled}
        onChange={(e) => {
          setEnabled(e.target.checked);
        }}
      />
      {enabled && (
        <div style={{ marginLeft: "10px" }}>
          <FormField {...rest} />
        </div>
      )}
      {info && (
        <span style={{ marginTop: "16px", marginLeft: "auto" }}>
          <Info text={info.text} img={info.img} />
        </span>
      )}
    </div>
  );
};

export default OptionalFormField;
